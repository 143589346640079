import { ProjectStatusType, WorkflowType } from '@/types/tanstack-query/generated';

import AppEnv from './app-env';

export const API_URL = AppEnv.get().api;
export const WS_URL = AppEnv.get().ws;
export const DOMAIN_URL = AppEnv.get().base;

export const BaseColors = [
  '#bec2c8',
  '#95a2b3',
  '#5e6ad2',
  '#26b5ce',
  '#4cb782',
  '#f2c94c',
  '#f2994a',
  '#f7c8c1',
  '#eb5757',
];

export const BaseWorkflows = [
  { type: WorkflowType.Backlog, name: 'Backlog' },
  { type: WorkflowType.Planned, name: 'Planned' },
  { type: WorkflowType.InProgress, name: 'In Progress' },
  { type: WorkflowType.Completed, name: 'Completed' },
  { type: WorkflowType.Canceled, name: 'Canceled' },
];

export const BaseProjectStatuses = [
  { type: ProjectStatusType.Backlog, name: 'Backlog' },
  { type: ProjectStatusType.Planned, name: 'Planned' },
  { type: ProjectStatusType.InProgress, name: 'In Progress' },
  { type: ProjectStatusType.Completed, name: 'Completed' },
  { type: ProjectStatusType.Canceled, name: 'Canceled' },
];
